import { writable } from "svelte/store";
import Home from "./routes/Home.svelte";
import Admin from "./routes/Admin.svelte";
import GridBuilder from "./routes/GridBuilder.svelte";
import Dashboard from "./routes/Dashboard.svelte";
import Configs from "./routes/Configs.svelte";
import ConfigsBeta from "./routes/ConfigsBeta.svelte";
import WidgetCustomization from "./routes/WidgetCustomization.svelte";
import CreateClient from "./routes/CreateClient.svelte";
import GoLive from "./routes/GoLive.svelte";
import Api from "./routes/Api.svelte";

export const routes = writable({
  dashboard: [
    {
      path: "/",
      title: "Home",
      icon: "dashboard",
      permissions: "hasHomePermission",
      component: Home,
    },
    {
      path: "dashboard",
      title: "Reports",
      icon: "reports",
      permissions: "hasHomePermission",
      component: Dashboard,
    },
  ],
  settings: [
    {
      path: "widget-customization",
      title: "Widget Customization",
      icon: "edit",
      permissions: "hasWidgetCustomizationPermission",
      component: WidgetCustomization,
    },
    {
      path: "grid-builder",
      title: "Grid Builder",
      icon: "carousel",
      permissions: "hasGridBuilderPermission",
      component: GridBuilder,
    },
  ],
  admin: [
    {
      path: "go-live",
      title: "Go Live",
      icon: "golive",
      permissions: "hasGoLivePermission",
      component: GoLive,
    },

    {
      path: "admin",
      title: "User Access",
      icon: "settings",
      permissions: "hasAdminPermissions",
      component: Admin,
    },
    {
      path: "api",
      title: "API Keys",
      icon: "code",
      permissions: "hasOpenApiAccess",
      component: Api,
    },
  ],
  shopbox: [
    {
      path: "create-client",
      title: "Create Client",
      icon: "add",
      permissions: "hasShopboxAdminPermissions",
      component: CreateClient,
    },
    {
      path: "configs",
      title: "Configs",
      icon: "code",
      permissions: "hasShopboxAdminPermissions",
      component: Configs,
    },
    {
      path: "configs-beta",
      title: "BETA - DO NOT USE",
      icon: "code",
      permissions: "hasShopboxAdminPermissions",
      component: ConfigsBeta,
    },
  ],
});
